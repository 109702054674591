import { Component } from '@angular/core';
import { ApiService } from '../../services/server.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment.development';
import { Web3Service } from '../../services/web3.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrl: './landing.component.scss'
})
export class LandingComponent {

  historyData: any;
  opportunities: any;
  chainId: any;
  balanceData: any;
  showData: boolean = false;
  routhPath:any=environment

  constructor(private callApi: ApiService, private router: Router, private web3:Web3Service) {
    this.callApi.subscribeEvent().subscribe((res: any) => {
      if (res?.eventName == "connected" && !this.showData) {
        this.getOpportunites()
      }
    })
  }

  ngOnInit() {
    this.getHistoryData({}, 1)
    this.chainId = sessionStorage.getItem('chainId')
  }

  async getHistoryData(payload: any, page: any) {
    this.callApi.getTransactions(payload, page).subscribe((res: any) => {
      this.historyData = res?.data?.dataSent
    })
    this.getOpportunites()
    this.balanceData = await this.web3.getBalance()
  }

  getOpportunites() {
    let network: any = sessionStorage
    this.callApi.topOpportunities({ network: network?.chainId }).subscribe(async (res: any) => {
      this.opportunities = res?.data?.opportunities
      if(res?.success){
        this.showData = true
      }
    })
  }

  swapData(data: any) {
    let network: any = sessionStorage
    data.srcAddress = data?.srcToken
    data.srcSymbol = data?.srcTokenSlug
    data.address = data?.destToken
    data.symbol = data?.destTokenSlug
    data.amount = 1
    data.srcDecimal = data?.srcDecimals
    data.destDecimal = data?.destDecimals
    this.callApi.publishEvent({ eventName: 'coinData', eventDetails: data })
    this.router.navigate([`/${this.routhPath.welcome}`], { queryParams: { network: network?.chainId } })
  }

}
