import { Component } from '@angular/core';
import { ApiService } from '../../services/server.service';
import { Router } from '@angular/router';
import { Web3Service } from '../../services/web3.service';
import { environment } from '../../../environments/environment.development';

@Component({
  selector: 'app-opportunity',
  templateUrl: './opportunity.component.html',
  styleUrl: './opportunity.component.scss'
})
export class OpportunityComponent {

  opportunities: any = [];
  chainId: any;
  localChainId: any;
  balanceData:any;
  address:any;
  routhPath:any=environment

  constructor(private callApi: ApiService, private router: Router,private web3:Web3Service) { }

  ngOnInit() {
    try {
      this.chainId = sessionStorage.getItem('chainId')
      this.localChainId = this.chainId
    } catch (error) { }
    
    this.getTopOppertunities(this.chainId)
  }

  async getTopOppertunities(network:any) {
    this.callApi.showSpinner()
    this.callApi.topOpportunities({network:network}).subscribe(async (res: any) => {
      if (await res?.success) {
        this.opportunities = res?.data?.opportunities
      }
      this.callApi.hideSpinner()
    }, error => {
      this.callApi.hideSpinner()
      this.callApi.showInfo('Try again later')
    })
    this.balanceData = await this.web3.getBalance()
    this.address = await this.callApi.getWalletAddress()
  }

  swapData(data: any) {
    data.srcAddress = data?.srcToken
    data.srcSymbol = data?.srcTokenSlug
    data.address = data?.destToken
    data.symbol = data?.destTokenSlug
    data.amount = 1
    data.srcDecimal = data?.srcDecimals
    data.destDecimal = data?.destDecimals
    this.callApi.publishEvent({ eventName: 'coinData', eventDetails: data })
    this.router.navigate([`/${this.routhPath.welcome}`], { queryParams: { network: this.localChainId } })
  }

  generateOpportunities() {
    this.callApi.showSpinner()
    this.callApi.generateTopOpportunities({network:this.localChainId}).subscribe(async (res: any) => {
      if (await res?.success) {
        this.getTopOppertunities(this.localChainId)
      }
      this.callApi.hideSpinner()
    }, error => {
      this.callApi.hideSpinner()
      this.callApi.showInfo('Try again later')
    })
  }

  netWorkChange(event:any){
    this.localChainId = event?.target?.value
    this.getTopOppertunities(this.localChainId)
    this.callApi.publishEvent({eventName:'networkChange',eventDetails:this.localChainId})
  }

}
