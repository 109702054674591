import { Component } from '@angular/core';
import { ApiService } from '../../services/server.service';
import { Web3Service } from '../../services/web3.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ethers } from 'ethers';
import { FlashbotService } from '../../services/flashbot.service';
import { Polygon, Bnb } from '../../services/web3.service';
import { environment } from '../../../environments/environment.development';


@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrl: './welcome.component.scss'
})
export class WelcomeComponent {

  selectedCoinData: any;
  priceRoutesData: any;
  estimatedProfit: any;
  checkInitialHit: boolean = false;
  network: any;
  balanceData:any;
  address:any;
  chainId :any;
  routhPath:any=environment

  constructor(private callApi: ApiService, private web3: Web3Service, private router: Router, private flashbot: FlashbotService, private route: ActivatedRoute) {
    this.callApi.subscribeEvent().subscribe(async (res: any) => {
      if (await res?.eventName == 'coinData') {
        this.selectedCoinData = res?.eventDetails
        if (this.checkInitialHit) {
          this.getPriceRoutes()
        }
      }
    })
  }

  ngOnInit() {
    this.route.queryParams.subscribe((res: any) => {
      this.network = res?.network
    })
    this.getPriceRoutes()
    if(!this.selectedCoinData){
      this.router.navigate([`/${this.routhPath.landing}`])
    }
  }

  async getPriceRoutes() {
    let localNetwork:any;
    try { localNetwork = Number(sessionStorage.getItem('chainId')) } catch (error) { }
    this.chainId = localNetwork
    let payload = {
      srcDecimal: this.selectedCoinData?.srcDecimal,
      destDecimal: this.selectedCoinData?.destDecimal,
      srcToken: this.selectedCoinData?.srcAddress,
      destToken: this.selectedCoinData?.address,
      amount: this.selectedCoinData?.amount * 10 ** 18,
      side: 'SELL',
      network: this.network ? this.network : localNetwork
    }
    this.callApi.gotSelectedCoinData(payload).subscribe(async (res: any) => {
      if (res?.success) {
        this.checkInitialHit = true
        this.priceRoutesData = res?.data
        this.estimatedProfit = ((Number(this.priceRoutesData?.transParam?.priceRoute?.destUSD) - Number(this.priceRoutesData?.transParam?.priceRoute?.srcUSD)) / Number(this.priceRoutesData?.transParam?.priceRoute?.destUSD)) * 100
      }

    })
    this.balanceData = await this.web3.getBalance()
    this.address = await this.callApi.getWalletAddress()
  }

  async submitCoinData() {
    let localNetwork:any;
    try { localNetwork = Number(sessionStorage.getItem('chainId')) } catch (error) { }
    if((Number(this.network) != Number(localNetwork)) && !isNaN(this.network) ){
      this.web3.openMetamask(this.network).then(async (res)=>{
        if(res){
          await this.accessContract()
        }
      })
    } else {
      this.accessContract()
    }
    
  }

  async accessContract(){
    this.callApi.showSpinner()
    let tx: any = await this.flashbot.requestForSwap(this.selectedCoinData?.srcAddress, this.selectedCoinData?.address, this.selectedCoinData?.amount, this.selectedCoinData)
    console.log('tx', '---', tx);
    if (tx?.hash) {
      let storage: any = sessionStorage.getItem('chainId')
      let payload = {
        "transactionHash": tx?.hash,
        "src_Address": this.selectedCoinData?.srcAddress,
        "dsc_Address": this.selectedCoinData?.address,
        "wallet_address": sessionStorage.getItem('flashaddress'),
        "contract_address": storage == 56 ? Bnb : Polygon,
        "amount": Number(this.selectedCoinData?.amount),
        "dexFrom": 3,
        "dexTo": 3,
        "network": sessionStorage.getItem('chainId')
      }
      this.callApi.setTransactions(payload).subscribe(async (res: any) => {

      })
      this.router.navigate([`/${this.routhPath.history}`])
    }
    this.callApi.hideSpinner()
  }

}
