import { Injectable } from "@angular/core";
import Web3 from "web3";
import { ethers } from 'ethers';
import { ApiService } from "./server.service";


declare const window: any;
@Injectable({
	providedIn: 'root'
})
export class Web3Service {

	constructor(
		private callApi: ApiService
	) { }

	private getAccounts = async () => {
		try {
			return await window.ethereum.request({ method: 'eth_accounts' });
		} catch (e) {
			return [];
		}
	}

	public openMetamask = async (payload: any) => {
		try {
			if (typeof window.ethereum !== 'undefined') {
				let chainId = await this.getNetworkDetails();
				if (payload === '') {
					if (chainId?.chainId === 137 || chainId?.chainId === 1 || chainId?.chainId === 56) {
						return this.getAddress();
					} else {
						await this.switchEthereumChain('137');
						return this.getAddress();
					}
				} else {
					await this.switchEthereumChain(payload);
					return this.getAddress();
				}
			} else {
				this.callApi.showInfo('Install MetaMask Extension');
			}
		} catch (error) {
			console.error("Error in openMetamask:", error);
		}
	};
	
	private async switchEthereumChain(chainId: string) {
		try {
			const chainIdHex = '0x' + parseInt(chainId).toString(16);
			await window.ethereum.request({
				method: 'wallet_switchEthereumChain',
				params: [{ chainId: chainIdHex }],
			});
		} catch (error:any) {
			if (error.code === 4902) {
				this.callApi.showInfo("This network is not available in your MetaMask, please add it");
				console.log("This network is not available in your MetaMask, please add it");
			} else {
				this.callApi.showError("Failed to switch to the network");
				console.log("Failed to switch to the network");
			}
		}
	}


	getNetworkDetails = async () => {
		try {
			const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
			const network: any = await provider.getNetwork();
			console.log(network);
			sessionStorage.setItem('chainId', network?.chainId)
			return network;
		} catch (error) {
			console.log(error);
		}

	}

	async getAddress() {
		window.web3 = new Web3(window.ethereum);
		let addresses = await this.getAccounts();
		if (!addresses.length) {
			try {
				addresses = await window.ethereum.enable();
			} catch (e) {
			}
		}
		sessionStorage.setItem('flashaddress', addresses.length ? addresses[0] : null)
		await this.callApi.getWalletAddress()
		await this.getSigner()
		await this.getNetworkDetails()
		return addresses.length ? addresses[0] : null;
	}

	public getSigner(): any {
		let provider = window.ethereum;
		if (typeof provider !== undefined) {
			const ether = new ethers.providers.Web3Provider(provider)
			const signer = ether.getSigner();
			return signer
		}
	}

	public async getBalance(){
		let provider = new Web3(window.ethereum)
		let balance:any = await provider.eth.getBalance(await this.callApi.getWalletAddress())
		balance = Number(balance)/10**18
		return balance
	}

}

export const token_abi = [
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "allowance",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "needed",
				"type": "uint256"
			}
		],
		"name": "ERC20InsufficientAllowance",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "sender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "balance",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "needed",
				"type": "uint256"
			}
		],
		"name": "ERC20InsufficientBalance",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "approver",
				"type": "address"
			}
		],
		"name": "ERC20InvalidApprover",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "receiver",
				"type": "address"
			}
		],
		"name": "ERC20InvalidReceiver",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "sender",
				"type": "address"
			}
		],
		"name": "ERC20InvalidSender",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			}
		],
		"name": "ERC20InvalidSpender",
		"type": "error"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			}
		],
		"name": "allowance",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "decimals",
		"outputs": [
			{
				"internalType": "uint8",
				"name": "",
				"type": "uint8"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "transfer",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	}
]

export const Polygon_abi = [
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "prevController",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "address",
				"name": "newController",
				"type": "address"
			}
		],
		"name": "Controllerchanged",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "account",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "FUNDTransferred",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "prevSigner",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "address",
				"name": "newSigner",
				"type": "address"
			}
		],
		"name": "SignControllerchanged",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "signer",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "SignerChanged",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newController",
				"type": "address"
			}
		],
		"name": "changeController",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newSigner",
				"type": "address"
			}
		],
		"name": "changeSignController",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "asset",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "premium",
				"type": "uint256"
			},
			{
				"internalType": "address",
				"name": "initiator",
				"type": "address"
			},
			{
				"internalType": "bytes",
				"name": "params",
				"type": "bytes"
			}
		],
		"name": "executeOperation",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"components": [
					{
						"internalType": "address[]",
						"name": "tokenAddress",
						"type": "address[]"
					},
					{
						"internalType": "uint256",
						"name": "profitValue",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "borrowedValue",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "amount",
						"type": "uint256"
					},
					{
						"internalType": "uint8[]",
						"name": "dexPath",
						"type": "uint8[]"
					},
					{
						"internalType": "bytes[]",
						"name": "swapCalldata",
						"type": "bytes[]"
					}
				],
				"internalType": "struct FlashBot.LoanParams",
				"name": "lparams",
				"type": "tuple"
			}
		],
		"name": "requestFlashLoan",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"components": [
					{
						"internalType": "address[]",
						"name": "tokenAddress",
						"type": "address[]"
					},
					{
						"internalType": "address",
						"name": "caller",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "profitValue",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "borrowedValue",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "amount",
						"type": "uint256"
					},
					{
						"internalType": "uint8[]",
						"name": "dexPath",
						"type": "uint8[]"
					},
					{
						"internalType": "bytes[]",
						"name": "swapCalldata",
						"type": "bytes[]"
					}
				],
				"internalType": "struct FlashBot.BLoanParams",
				"name": "blparams",
				"type": "tuple"
			}
		],
		"name": "requestFlashLoanForBot",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"stateMutability": "payable",
		"type": "receive"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "txns",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "profits",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "tradeVolume",
				"type": "uint256"
			}
		],
		"name": "updatesPlatformStats",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "token",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "withdrawFunds",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_addressProvider",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "_ZERO_X_ADDRESS",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "_ONEINCHADDRESS",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "_OPENOCEANADDRESS",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "_PARASWAPADDRESS",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "_Controller",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "_signer",
				"type": "address"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [],
		"name": "ADDRESSES_PROVIDER",
		"outputs": [
			{
				"internalType": "contract IPoolAddressesProvider",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "Controller",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes32",
				"name": "",
				"type": "bytes32"
			}
		],
		"name": "isValidSign",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "POOL",
		"outputs": [
			{
				"internalType": "contract IPool",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "signController",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalProfit",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalTradelimit",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalTxns",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
]

export const Polygon = "0x69F52C453C8147f3D516b65762dF5d893A5Dc138"

export const Bnb_abi = [
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "prevController",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "address",
				"name": "newController",
				"type": "address"
			}
		],
		"name": "Controllerchanged",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "account",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "FUNDTransferred",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "prevSigner",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "address",
				"name": "newSigner",
				"type": "address"
			}
		],
		"name": "SignControllerchanged",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "signer",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "SignerChanged",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newController",
				"type": "address"
			}
		],
		"name": "changeController",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newSigner",
				"type": "address"
			}
		],
		"name": "changeSignController",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "asset",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "premium",
				"type": "uint256"
			},
			{
				"internalType": "address",
				"name": "initiator",
				"type": "address"
			},
			{
				"internalType": "bytes",
				"name": "params",
				"type": "bytes"
			}
		],
		"name": "executeOperation",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"components": [
					{
						"internalType": "address[]",
						"name": "tokenAddress",
						"type": "address[]"
					},
					{
						"internalType": "uint256",
						"name": "profitValue",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "borrowedValue",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "amount",
						"type": "uint256"
					},
					{
						"internalType": "uint8[]",
						"name": "dexPath",
						"type": "uint8[]"
					},
					{
						"internalType": "bytes[]",
						"name": "swapCalldata",
						"type": "bytes[]"
					}
				],
				"internalType": "struct FlashBot.LoanParams",
				"name": "lparams",
				"type": "tuple"
			}
		],
		"name": "requestFlashLoan",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"components": [
					{
						"internalType": "address[]",
						"name": "tokenAddress",
						"type": "address[]"
					},
					{
						"internalType": "address",
						"name": "caller",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "profitValue",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "borrowedValue",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "amount",
						"type": "uint256"
					},
					{
						"internalType": "uint8[]",
						"name": "dexPath",
						"type": "uint8[]"
					},
					{
						"internalType": "bytes[]",
						"name": "swapCalldata",
						"type": "bytes[]"
					}
				],
				"internalType": "struct FlashBot.BLoanParams",
				"name": "blparams",
				"type": "tuple"
			}
		],
		"name": "requestFlashLoanForBot",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"stateMutability": "payable",
		"type": "receive"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "txns",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "profits",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "tradeVolume",
				"type": "uint256"
			}
		],
		"name": "updatesPlatformStats",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "token",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "withdrawFunds",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_addressProvider",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "_ZERO_X_ADDRESS",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "_ONEINCHADDRESS",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "_OPENOCEANADDRESS",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "_PARASWAPADDRESS",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "_Controller",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "_signer",
				"type": "address"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [],
		"name": "ADDRESSES_PROVIDER",
		"outputs": [
			{
				"internalType": "contract IPoolAddressesProvider",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "Controller",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes32",
				"name": "",
				"type": "bytes32"
			}
		],
		"name": "isValidSign",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "POOL",
		"outputs": [
			{
				"internalType": "contract IPool",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "signController",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalProfit",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalTradelimit",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalTxns",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
]

export const Bnb = "0x125342c181B24D677e934C721abe3492B5206661"
