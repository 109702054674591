import { Injectable, inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { ApiService } from '../services/server.service';
import { environment } from '../../environments/environment.development';


export const securityCheckGuardBeforLogin: CanActivateFn = (route, state) => {
  return inject(NotAlloePermissionService).canActivate();
};

@Injectable()

export class NotAlloePermissionService {
  constructor(private router: Router, private callApi: ApiService) { }
  canActivate(): boolean {
    let token;
    token = this.callApi.getUserToken();
    token = token!=null ? false : true
    if (!token) {
      this.router.navigate([`/${environment.landing}`])
    }
    return token;
  }

}

export const securityCheckGuardAfter: CanActivateFn = (route, state) => {
  return inject(AlloePermissionService).canActivate();
};

@Injectable()

export class AlloePermissionService {
  constructor(private router: Router, private callApi: ApiService) { }
  canActivate(): boolean {
    let token;
    token = this.callApi.getUserToken()
    token = token != null ? true : false;
    if (!token) {
      this.router.navigate([`/${environment.login}`])
    }
    return token;
  }

}