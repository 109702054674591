import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Web3Service, token_abi, Polygon_abi, Polygon,Bnb_abi,Bnb} from "./web3.service"
import { ethers } from 'ethers';
import axios from 'axios';
import { ApiService } from "./server.service";




@Injectable({
    providedIn: 'root'
})

export class FlashbotService {
    URL = 'http://192.168.0.68:8123/'
    Paraswap_price_API_URL = "https://apiV5.paraswap.io/prices";
    Paraswap_swap_API_URL :any ;
    API_QUOTE_URL : any;
    OPENOCEAN_URL :any;
    API_URL :any;
    generator_abi : any;
    token_abi = token_abi
    generator : any;
    provider:any;

    constructor(private http: HttpClient,
        private web3:Web3Service,private callApi:ApiService
        ) { }

    ngOnInit(){
        try {
            var storedData:any = sessionStorage?.getItem('chainId')
            this.Paraswap_swap_API_URL = `https://apiV5.paraswap.io/transactions/${storedData}/` //getSwapTransaction
            this.OPENOCEAN_URL = `https://open-api-pro.openocean.finance/v3/${storedData}/swap_quote?`
            this.API_URL = `https://api.1inch.dev/swap/v6.0/${storedData}/swap?` //getSwapInchTransaction
        } catch (error) { }

    }

    async  requestForSwap(tokenIn:any, tokenOut:any, amountIn:any, dex:any) {
        try {
            var StorageSession:any = sessionStorage;
            this.generator_abi = StorageSession?.chainId == 56 ? Bnb_abi : Polygon_abi
            this.generator = StorageSession?.chainId == 56 ? Bnb : Polygon
            this.API_QUOTE_URL = StorageSession?.chainId == 56 ? 'https://bsc.api.0x.org/swap/v1/quote' : StorageSession?.chainId == 137 ? 'https://polygon.api.0x.org/swap/v1/quote' : 'https://api.0x.org/swap/v1/quote'
            amountIn = await ethers.utils.parseUnits((amountIn).toString() ,(await this.getDecimal(tokenIn))).toString();
            let contract:any = await this.contractInstance(this.generator_abi, this.generator);
            console.log("enter");
            let responce = await this.getSwapData([ tokenIn, tokenOut], amountIn);
            console.log(responce, amountIn, "ssssss")
            // console.log((await contract.totalTxns.call()).toString(), "contract");
            let passingData:any = [[tokenIn, tokenOut], 1000, amountIn,amountIn, responce.bestDex, responce.swapData,StorageSession?.chainId,dex]
            let estimateStatus:any = await this.checkWEstrimateGasFee(passingData)
            console.log(4);
            
            console.log('57',estimateStatus);
            if(await estimateStatus){
                console.log([[tokenIn, tokenOut],1000, amountIn,amountIn, responce.bestDex, responce.swapData], {gasPrice: ethers.utils.parseUnits('37', 'gwei'), gasLimit: 10000000});   
                let tx = await contract.requestFlashLoan([[tokenIn, tokenOut], 1000, amountIn,amountIn, responce.bestDex, responce.swapData], {gasPrice: ethers.utils.parseUnits('60', 'gwei'), gasLimit: 10000000}).then((dt:any)=>{ console.log("respsss",dt); return dt}).catch((e:any)=>{ console.log(e, "eeeeeeee"); return null;})
                console.log('tx',tx);
                
                await tx?.wait();
                // .then((respr:any)=>{return respr;}).catch((e:any)=>{ console.log(e); return null});
                let txHash = await this.provider?.waitForTransaction(tx?.hash);
                console.log({"Success..txHash":txHash?.hash, "Status":txHash?.status}); 
            }
        } catch (error:any) {
            console.error(error.message)
        }
    }
    async contractInstance(abi:any, address:any) {
        try {
            console.log(address, "addresss");
            
            let contract = new ethers.Contract(address, abi, await this.web3.getSigner())
            return contract;
        } catch (error:any) {
            console.log(error);
            return null;
        }        
    }
    async  getSwapTransaction(sellToken:any, buyToken:any, amount:any) {
        let priceRoute;
        try {
          const config = {
              headers: {
                  "Authorization": "e2c447d7-49b8-4573-8936-14b7fa16cf90"
              },
              params: {
                srcToken: sellToken,
                srcDecimals: await this.getDecimal(sellToken),
                destToken: buyToken,
                destDecimals:  await this.getDecimal(buyToken),
                amount: amount,
                network: sessionStorage?.getItem('chainId'),
                side:"SELL",
                slippage: 50
                    }
          };
          let response:any = await axios.get(this.Paraswap_price_API_URL, config).then((resp)=>{
            console.log(resp, "routeresponse");
            
            return resp;
          })
          .catch((e)=>{
            console.log(e);
            return null;
          });
          
          priceRoute = (response?.data?.priceRoute);
          try {
          let params = {
            srcToken: sellToken,
            destToken: buyToken,
            srcAmount: amount,
            destAmount: priceRoute?.destAmount,
            priceRoute: priceRoute,
            userAddress: this.generator,
            receiver: this.generator,
            partner: "FABC",
            srcDecimals:await this.getDecimal(sellToken),
            destDecimals: await this.getDecimal(buyToken),
            ignoreChecks:true,
        }
            response = await axios.post(this.Paraswap_swap_API_URL, params);
            return({data:response.data.data, amount: priceRoute.destAmount})
          } catch (error) {
              console.log('Error making API request:', error);
              return null;
          }
        } catch (error) {
          console.log(error);
          return null;
        }
      }
    
    async  getswapData(sellToken:any, buyToken:any, sellAmount:any) {
        const config = {
            headers: {
                  "0x-api-key": "5bd856a4-872c-4bea-bda5-dfdac38547e4"
              },
            params: {
                  "sellToken": sellToken,
                  "buyToken": buyToken,
                  "sellAmount": sellAmount,
                  "enableSlippageProtection": true,
                  "taker": this.generator,
                  "slippagePercentage": 1 
                }
        };
        try {
            const response:any = await axios.get(this.API_QUOTE_URL, config).then((resp:any)=> { return resp}).catch((e)=>{ console.log(e, "error"); return null;});
            return ({data:response?.data?.data, amount: response?.data?.buyAmount})
        } catch (error) {
            console.error('Error making API request:', error);
            return null;
        }
    }
    
    
    async  getDecimal(token:any) {
        console.log("decccc", token);        
        let contract:any = await this.contractInstance(this.token_abi, token);
        console.log(token,"===",parseInt(await contract.decimals()));        
        return parseInt(await contract.decimals())
    }
    
    async  getSymbol(token:any) {
        let contract:any = await this.contractInstance(this.token_abi, token);
        console.log(await contract.symbol())
        return (await contract.symbol()).toString();
    }
    
    async  getOpenoceanSwapData(sellToken:any, buyToken:any, sellAmount:any) {
        const config = {
            headers: {
                "apikey": "",
            },
            params: {
                  "inTokenAddress": sellToken,
                  "outTokenAddress": buyToken,
                  "amount": sellAmount,
                  "slippage": 10,
                  "gasPrice": 3,
                  "account" : this.generator
    
                }
        };
        try {
            let response:any = await axios.get(this.OPENOCEAN_URL, config);
            return ({data:response.data.data.data, amount: response.data.data.outAmount})
        } catch (error) {
            console.error('Error making API request:', error);
            return null;
        }
    }

    async getSwapInchTransaction(sellToken: any, buyToken: any, amount: any) {
        console.log('check');
        var StorageSession:any = sessionStorage;
        this.API_URL = `https://api.1inch.dev/swap/v6.0/${StorageSession?.chainId}/swap` 
        try {
            const config = {
                headers: {
                    "Authorization": "ySEhMreztXuiTH86nUijLOA8BGdVA2b0",
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                },
                params: {
                    src: sellToken,
                    dst: buyToken,
                    amount: amount,
                    slippage: 1,
                    from: this.generator, // Make sure this.generator is properly defined
                    disableEstimate: true,
                }
            };
            console.log('check-1',config);
            const response = await axios.get(this.API_URL,config);
            console.log(response.data, "oneInch");
            return { data: response.data?.tx?.data, amount: response.data?.dstAmount };
        } catch (error:any) {
            console.error('Error making API request:', error);
            // Log more specific error details if available
            if (error.response) {
                console.error('Response:', error.response.data);
            }
            return null;
        }
    }
    
    async  getSwapData(tokens:any, amountIn:any) {
        let dex:any = [];
        let bestPrice:any = 0;
        let bestdex:any = 0;
        let calldata:any = [];
        let responce:any;
        let data:any = 0;
        
        for (let index = 0; index < tokens.length; index++) {
            
            if(index == 0) {
                responce = await this.getswapData(tokens[index], tokens[index + 1], amountIn);
                if(responce?.amount > bestPrice) {
                    data = responce?.data;
                    bestPrice = responce?.amount;
                    bestdex = 0;
                }
                // responce = await this.getOpenoceanSwapData(tokens[index], tokens[index + 1], amountIn/10**await this.getDecimal(tokens[index]));
                // if(responce.amount > bestPrice) {
                //     data = responce.data;
                //     bestPrice = responce.amount;
                //     bestdex = 2;
                // }
                // responce = await this.getSwapInchTransaction(tokens[index], tokens[index + 1], amountIn);
                // if(responce?.amount > bestPrice) {
                //     data = responce?.data;
                //     bestPrice = responce?.amount;
                //     bestdex = 1;
                // }
                
                responce = await this.getSwapTransaction(tokens[index], tokens[index + 1], amountIn);
                if(responce?.amount > bestPrice) {
                    data = responce?.data;
                    bestPrice = responce?.amount;
                    bestdex = 3;
                }
                amountIn = bestPrice;
                bestPrice = 0;
                dex.push(bestdex)
                calldata.push(data)
                console.log({sell: amountIn, bestdex: dex});
            }
            if(index == 1) {
                responce = await this.getswapData(tokens[index], tokens[index - 1], amountIn);
                if(responce?.amount > bestPrice) {
                    data = responce?.data;
                    bestPrice = responce?.amount;
                    bestdex = 0;
                }
                // responce = await this.getOpenoceanSwapData(tokens[index], tokens[index - 1], amountIn/10**await this.getDecimal(tokens[index]));
                // if(responce.amount > bestPrice) {
                //     data = responce.data;
                //     bestPrice = responce.amount;
                //     bestdex = 2;
                // }
                // responce = await this.getSwapInchTransaction(tokens[index], tokens[index - 1], amountIn);
                // if(responce?.amount > bestPrice) {
                //     data = responce?.data;
                //     bestPrice = responce?.amount;
                //     bestdex = 1;
                // }
                responce = await this.getSwapTransaction(tokens[index], tokens[index - 1], amountIn);
                if(responce?.amount > bestPrice) {
                    data = responce?.data;
                    bestPrice = responce?.amount;
                    bestdex = 3;
                }
                dex.push(bestdex);
                calldata.push(data)
                console.log({buy: bestPrice, bestdex: dex}); 
            }
        }
        console.log("return");
        return ({bestDex: dex, swapData: calldata})
    }

    checkWEstrimateGasFee(passingData:any){
        return new Promise(async(resolve:any)=>{
          this.callApi.checkTransactions(passingData).subscribe(async(res:any)=>{
                if(res?.success){
                   await resolve (res?.success)
                } else {
                    this.callApi.showError(res?.message)
                    await resolve (null)
                }
            })
        })
    }

}
