<section class="header-padding">
    <div class="container">
        <div class="header-banner-bg position-relative p-0">
            <div class="z-1 position-relative bal-banner">
                <h4 class="fw-semibold">{{chainId=='56'?'BNB':chainId=='137'?'Polygon':'Ethereum'}} Market</h4>
                <h2>{{balanceData|number:'0.0-4'}}</h2>
                
                <div class="text-end">
                    <div class="button-primary cursor-pointer" [routerLink]="'/'+routhPath.history">
                        View Transactions
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <div class="box-bg mt-4">
                    <h5>Recent Transaction</h5>
                    <div class="mt-4 table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Time</th>
                                    <th>Trx Hash</th>
                                    <th>From</th>
                                    <th>To</th>
                                    <th>Amount</th>
                                    <th>Profit</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                @for(data of historyData?.slice(0,5);track data){
                                <tr>
                                    <td class="table-content p-3">{{data?.createdAt|date:'MMM d, y, h:mm a'}}</td>
                                    <td class="table-content">
                                        <a [href]="data?.hashLink" target="_blank"
                                            [ngClass]="data?.status?'text-dark fw-bolder':'text-danger fw-bolder'"
                                            class="text-decoration-none">
                                            {{(data?.transactionHash|slice:0:5)+'...'+(data?.transactionHash|slice:-5)}}
                                        </a>
                                    </td>
                                    <td class="table-content">{{(data?.from|slice:0:5)+'...'+(data?.from|slice:-5)}}
                                    </td>
                                    <td class="table-content">{{(data?.to|slice:0:5)+'...'+(data?.to|slice:-5)}}</td>
                                    <td class="table-content">{{data?.amount}}</td>
                                    <td class="table-content">{{data?.profitTokenAmount}}</td>
                                    <td class="table-content"
                                        [ngClass]="data?.status?'text-success fw-bolder':'text-danger fw-bolder'">
                                        {{data?.status?'Success':'Failed'}}
                                    </td>
                                </tr>
                                } @empty {
                                <tr>
                                    <td colspan="12">
                                        No history found
                                    </td>
                                </tr>
                                }

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="box-bg mt-4">
                    <h5>Opportunities</h5>
                    <div class="mt-4 table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Swap Fee</th>
                                    <th>Loan Fee</th>
                                    <th>Total Gas Fee</th>
                                    <th>Profit</th>
                                    <th>Src Token</th>
                                    <th>Dest Token</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                @for(data of opportunities?.slice(0,5);track data){
                                <tr>
                                    <td class="table-content">0.1{{data?.srcTokenSlug}}</td>
                                    <td class="table-content">0.05</td>
                                    <td class="table-content">0.15{{data?.srcTokenSlug}}</td>
                                    <td class="table-content" [ngClass]="data?.profit > 0?'text-dark':'text-danger'">
                                        {{data?.profit?(data?.profit|number: '0.0-2')+'%':''}}</td>
                                    <td class="table-content">{{data?.srcTokenSlug}}</td>
                                    <td class="table-content">{{data?.destTokenSlug}}</td>
                                    <td>
                                        <button class="button-primary" (click)="swapData(data)">Swap</button>
                                    </td>
                                </tr>
                                }@empty{
                                <tr>
                                    <td class="table-content" colspan="12">No opportunities found</td>
                                </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="false">
            <div class="col-lg-6">

                <div class="box-bg mt-4">
                    <h4 class="">Asset to Supplies</h4>
                    <div class="mt-4 table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">Assets</th>
                                    <th scope="col">Wallet Bal</th>
                                    <th scope="col">Apy</th>
                                    <th scope="col">Can be collateral
                                    </th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div class="d-flex align-items-center">
                                            <img src="assets/images/btc.png" class="img-fluid me-2" width="18" alt="">
                                            <h5 class="mb-0 fw-bold">BTC</h5>
                                        </div>
                                    </td>
                                    <td>0.00</td>
                                    <td>0.0%</td>
                                    <td>-</td>
                                    <td>
                                        <div class="d-flex">
                                            <div>
                                                <div class="button-primary btn-sm">
                                                    Supply
                                                </div>
                                            </div>
                                            <div class="ms-3">
                                                <div class="button-primary btn-sm">
                                                    ...
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">

                <div class="box-bg mt-4">
                    <h4 class="">Asset to Borrows</h4>
                    <div class="mt-4 table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">Assets</th>
                                    <th scope="col">Wallet Bal</th>
                                    <th scope="col">Apy</th>
                                    <th scope="col">Can be collateral
                                    </th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div class="d-flex align-items-center">
                                            <img src="assets/images/btc.png" class="img-fluid me-2" width="18" alt="">
                                            <h5 class="mb-0 fw-bold">BTC</h5>
                                        </div>
                                    </td>
                                    <td>0.00</td>
                                    <td>0.0%</td>
                                    <td>-</td>
                                    <td>
                                        <div class="d-flex">
                                            <div>
                                                <div class="button-primary btn-sm">
                                                    Borrow
                                                </div>
                                            </div>
                                            <div class="ms-3">
                                                <div class="button-primary btn-sm">
                                                    Details
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>