import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment.development";
import { BehaviorSubject } from "rxjs";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";


@Injectable({
    providedIn: 'root'
})

export class ApiService {

    URL = environment.backEndUrl
    subject = new BehaviorSubject<any>('')
    headers = new HttpHeaders()
    token: any;

    constructor(private http: HttpClient, private spinner: NgxSpinnerService, private toastr: ToastrService) {
        try {
            this.token = sessionStorage.getItem('flashtoken')
            this.headers = new HttpHeaders().set('_token', this.token)
        } catch (error) { }
    }

    publishEvent(payload: any) {
        this.subject.next(payload)
    }

    subscribeEvent() {
        return this.subject.asObservable()
    }

    showSpinner() {
        this.spinner.show()
    }

    hideSpinner() {
        this.spinner.hide()
    }

    showSuccess(payload: any) {
        this.toastr.success(payload)
    }

    showError(payload: any) {
        this.toastr.error(payload)
    }

    showInfo(payload: any) {
        this.toastr.info(payload)
    }

    showWarning(payload: any) {
        this.toastr.warning(payload)
    }

    getWalletAddress(): any {
        try {
            return sessionStorage.getItem('flashaddress')
        } catch (error) {
        }
    }

    getToken(network: any) {
        return this.http.get(this.URL + 'flashbot/getTokens?network=' + network)
    }

    gotSelectedCoinData(payload: any) {
        return this.http.post(this.URL + 'flashbot/getPriceRoutes', payload)
    }

    //Opportunities

    topOpportunities(payload: any) {
        return this.http.post(this.URL + 'flashbot/topOpportunities', payload)
    }

    generateTopOpportunities(payload: any) {
        return this.http.post(this.URL + 'flashbot/generateTopOpportunities', payload)
    }

    getTransactions(payload: any, page: any) {
        return this.http.post(this.URL + 'flashbot/getTransactions?page=' + page, payload)
    }

    setTransactions(payload: any) {
        return this.http.post(this.URL + 'flashbot/setTransactions', payload)
    }

    //Cron
    cornActions(payload: any) {
        return this.http.post(this.URL + 'flashbot/cornActions', payload)
    }

    // Basic Func
    login(payload: any) {
        return this.http.post(this.URL + 'user/login', payload)
    }
    logOut() {
        return this.http.get(this.URL + 'user/logOut', { headers: this.headers })
    }

    getUserToken() {
        try {
            this.token = sessionStorage?.getItem('flashtoken')
            this.headers = new HttpHeaders().set('_token', this.token)
            return this.token
        } catch (error) { }
    }

    checkTransactions(payload: any) {
        return this.http.post(this.URL + 'flashbot/checkTransactions', payload)
    }

}