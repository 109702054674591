<ngx-spinner type="ball-clip-rotate-pulse"></ngx-spinner>
<section class="bg-white bg_img">
    <div class="container-fluid">
        <div class="row vh-100 align-items-center justify-content-end">
            <div class="col-xxl-3 col-xl-4 col-lg-5 col-md-8 col-sm-10 col-11 mx-auto">
                <form class="text-center p-sm-5 input-form" [formGroup]="loginForm" (ngSubmit)="submitLoginData()">
                    <img src="assets/images/logo.png" alt="" width="250"
                        class="img-fluid cursor-pointer mb-4">
                    <h1 class="fw-bold mb-2">LOGIN</h1>
                    <!-- <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit</p> -->
                    <div class="form-floating">
                        <input type="email" class="form-control py-2 border-0 rounded-2 bg-input shadow-none pt-20"
                            id="floatingInput" formControlName="email" placeholder="name@example.com">
                        <label for="floatingInput">Email address</label>
                    </div>
                    <div class="position-relative">
                        <div class="form-floating mt-3">
                            <input [type]="!showPassword?'password':'text'"
                                class="form-control py-2 border-0 rounded-2 bg-input shadow-none pt-20"
                                id="floatingPassword" formControlName="password" placeholder="Password">
                            <label for="floatingPassword">Password</label>
                        </div>
                        <div class="position-absolute top-0 end-0 mt-3 text-end">
                            <a class="text-dark" (click)="change()">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    class="bi bi-eye-fill me-4 pe-auto" viewBox="0 0 16 16" *ngIf="showPassword">
                                    <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                                    <path
                                        d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    class="bi bi-eye-slash-fill me-4 pe-auto" viewBox="0 0 16 16" *ngIf="!showPassword">
                                    <path
                                        d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7 7 0 0 0 2.79-.588M5.21 3.088A7 7 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474z" />
                                    <path
                                        d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12z" />
                                </svg>
                            </a>
                        </div>
                    </div>
                    <div class="">
                        <button class="btn button-secondary mt-4 w-100" type="submit">Sign in</button>
                    </div>
                </form>
            </div>
            <div class="col-lg-6 d-lg-block d-none">
                <!-- <div
                                class="">
                                <img src="../../../assets/images/login/login-bg.png"
                                    class="img-fluid animate__animated animate__zoomIn animate__slow animate__delay-1s"
                                    alt="robot" >
                            </div>
                -->
            </div>
        </div>
    </div>
</section>